import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import HomeView from '../views/home/HomeView';
import PartnersView from '../views/partners/PartnersView';
import RegisterView from '../views/user/RegisterView';
import LoginView from '../views/user/LoginView';
import ForgotPasswordView from '../views/user/ForgotPasswordView';
import ResetPasswordView from '../views/user/ResetPasswordView';
import ResetPasswordConfirmView from '../views/user/ResetPasswordConfirmView';
import ItemsView from '../views/items/ItemsView';
import SearchResult from '../views/search/SearchResult';
import ItemDetail from '../views/items/detail/ItemDetail';
import AboutReCRQLView from '../views/about/AboutReCRQLView';
import SupportView from '../views/support/SupportView';
import ItemCheckout from '../views/checkout/ItemCheckout';
import FAQView from '../views/faq/FAQView';
import CheckoutRouteProvider from '../views/checkout/CheckoutRouteProvider';
import CheckoutConfirm from '../views/checkout/CheckoutConfirm';
import Layout from '../views/common/Layout';
import VerifyEmail from '../views/verifyEmail/VerifyEmail';
import VerifyEmailConfirmation from '../views/verifyEmail/VerifyEmailConfirmation';
import TOS from '../views/tos/TOS';
import PrivacyPolicy from '../views/privacyPolicy/PrivacyPolicyView';
import * as auther from '../utils/auth';
import * as localStorage from '../utils/localStorage';
import AuthContext from '../contexts/AuthContext';
import { useSearchParams } from '../utils/useSearchParams';
import useLocalStorageEventListener from '../utils/useLocalStorageListener';
import { properties } from '../theme';

function NavigateRedirect({ to, redirectFrom }: { to: string, redirectFrom: string }) {
  return <Navigate to={`${to}?redirectUri=${redirectFrom}`} />;
}

function AuthGate () {
  const [{ isAuthenticated }] = useContext(AuthContext);
  const location = useLocation();
  return (
    <>
      {isAuthenticated === false
      ? <NavigateRedirect to='/login' redirectFrom={location.pathname} />
      : <Outlet />}
    </>
  );
}

export default function AppRoutes() {
  const [{ isAuthenticated }, setAuthContext] = useContext(AuthContext);
  const [{ redirectUri }] = useSearchParams<{ redirectUri: string|undefined }>();
  useEffect(() => {
    auther.authenticate().catch(() => true);
  }, []);
  useLocalStorageEventListener('isAuthenticated', () => {
    setAuthContext(p => ({
      ...p,
      auth: localStorage.getItem('auth'),
      isAuthenticated: localStorage.getItem('isAuthenticated'),
    }));
  }, true);
  return (
    <Routes>
      <Route element={<Layout display='attached' />}>
        <Route path="/" element={<HomeView />} />
        {properties.navLinks.map(({
          path,
          label,
          defaultFilters,
          categories
        }) => (
          <Route key={path}>
            <Route path={path} element={<ItemsView title={label} categories={categories} defaultFilters={defaultFilters} />} />
            <Route path={`${path}/:id`} element={<ItemDetail />} />
          </Route>
        ))}
        <Route path="search/:query" element={<SearchResult />} />
        <Route path="about" element={<AboutReCRQLView />} />
        <Route path="support" element={<SupportView />} />
        <Route path="faq" element={<FAQView />} />
        <Route path="tos" element={<TOS />} />
        <Route path="gdpr" element={<PrivacyPolicy />} />
        <Route path="partners" element={<PartnersView />} />
        <Route path="purchase" element={<CheckoutRouteProvider />}>
          <Route path=":id" element={<CheckoutConfirm />} />
        </Route>
      </Route>
      <Route element={<Layout display='detached' />}>
        <Route path="checkout" element={<CheckoutRouteProvider />}>
          <Route path=":id" element={<ItemCheckout />} />
        </Route>
        {isAuthenticated !== true && (
          <>
            <Route path="register" element={<RegisterView />} />
            <Route path="login" element={<LoginView />} />
            <Route path="forgot-password" element={<ForgotPasswordView />} />
            <Route path="reset-password" element={<ResetPasswordView />} />
            <Route path="reset-password/confirm" element={<ResetPasswordConfirmView />} />
            <Route path="verify-email" element={<VerifyEmail />}/>
            <Route path="verify-email/confirm" element={<VerifyEmailConfirmation />} />
          </>
        )}
      </Route>
      <Route path="*" element={<Navigate to={redirectUri ?? "/"} />} />
    </Routes>
  );
}
